table { 
    border-collapse: collapse; 
}
tr { 
    border-top: 1px solid black; 
    border-bottom: 1px solid black;
}

tr:nth-child(even) {background-color: #f2f2f2;}

tr:nth-child(odd) {background-color: #a8ddf9;}
thead th{background-color: #f9a8a8;}

th, td{ 
    padding-top: 10px;
    border-left: 1px solid black; 
    border-right: 1px solid black;
}

td input{
    width: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
td input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

td .cell-top{
    text-align: right;
    top: 0px;
    right: 0px;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}
td .cell-bottom{
    text-align: right;
    top: 0px;
    right: 0px;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}
td .btn-up,.btn-down {
    height: 16px;
    width: 32px;
    cursor: pointer;
    transition: all .3s;
    padding: 0;
    margin: 0;
}
td .btn-down {
    background: url(https://tiermaker.com/images/chart/chart/arrowdown.png) center no-repeat;
}
td .btn-up {
    background: url(https://tiermaker.com/images/chart/chart/arrowup.png) center no-repeat;
}