nav {
    background-color: #333;
    padding: 10px;
    display: flex;
    align-items: center; /* Center elements horizontally */
    text-align: center;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    background-color: #baacac;
    display: inline;
    margin-right: 10px;
}

.search-input {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
}

button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.logo {
    height: 20px;
}
