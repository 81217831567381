.playerList-container, .property-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.property-container li{
    width: 100%;
    background-color: aquamarine;
}
.left-container {
    left: 0px;
    position: relative;
    width: 40%;
    float: left;
}
.right-container {
    float: right;
    right: 0px;
    position: relative;
    width: 60%;
}